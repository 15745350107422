// components/MyModal.js
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoginPage from "../Login/LoginPage";
import Register from "../Login/Register";
import { loadData } from "@/utils/localStorage";
import styles from '@/styles/BuyModal.module.css'
// import './BuyModal.css'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BuyModal = ({ open, dataset, setAmount, setSelectCourse, orderFunction, handleClose }) => {
  const [data, setData] = useState(0);
  const [user, setUser] = useState(null);
  const [selectedOption, setSelectedOption] = useState(`option1`);
  console.log(dataset, "dd119")
  const balance_due = dataset.fees - dataset?.partial_payment?.[0] - dataset?.pending_payment

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setUser(loadData("user"));
  }, [data]);
  useEffect(() => {
    data == 1 && handleClose();
  }, [data]);
  console.log(data, "dt11");

  const arr=[
    {
      fees: 5500,
      description:'conditional internship offer'
    },
    {
      fees: 7000,
      description:'With guaranteed internship offer once remaining balance is cleared and course is completed'
    }
  ]
  console.log(selectedOption)
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
     
      <Box sx={style}>
      <button style={{display:`flex`, justifyContent:`end`, alignItems:`center`, marginLeft:`auto`, border:`none`}} onClick={()=>handleClose()}>X</button>
        {/* {screen==1?<LoginPage />:<Register />} */}
        {/* <LoginPage handleClose={handleClose} /> */}
       
            <>
{/* <div className={styles.container}>
          <div className={styles.feesbuttoncontainer}><label>Fees: <span className={styles.textcontent}>{`₹ ${ele.fees}`}</span></label>
          <button className={styles.button}>Buy Now</button></div>
  
  <label>Description:</label>
  <p className={styles.textcontent}>{ele.description}</p>
  <p>-----------------------------------------------</p>
</div> */}
 <div className= {styles.paymentcontainer}>
      <div className={styles.paymentoptions}>
        <div className={ selectedOption=== 'option1'?styles[`optioncard1`]:styles[`optioncard`]}>
          <input
            type="radio"
            id="option1"
            name="payment"
            value="option1"
            checked={selectedOption === 'option1'}
            onChange={handleOptionChange}
          />
          <label htmlFor="option1">
            <h5>  &nbsp;Fees :  &nbsp;₹ {dataset?.partial_payment?.[0]}</h5>
            <b>Description:</b>
            <h6>Get conditional offer letter now and pay the remaining Rs {balance_due} after successfully completing the course and getting the final internship offer from a reputed organisation.</h6>
          </label>
        </div>
        
   
      </div>
     
    
    </div>
    <div className= {styles.paymentcontainer}>
    {/* <div className={styles.paymentoptions}>
        <div className={ selectedOption=== 'option2'?styles[`optioncard1`]:styles[`optioncard`]}>
          <input
            type="radio"
            id="option2"
            name="payment"
            value="option2"
            checked={selectedOption === 'option2'}
            onChange={handleOptionChange}
          />
          <label htmlFor="option2">
            <h5> &nbsp;Fees:  &nbsp;₹ 7000</h5>
            <b>Description:</b>
            <h6>With guaranteed internship offer once remaining balance is cleared and course is completed</h6>
          </label>
        </div>
        
   
      </div>*/}
      </div> 
    
</>
        
      <button className={styles.buynowbutton}onClick={() => {
  if (selectedOption === 'option2') {
    setAmount(7000);
    setSelectCourse({
      course_id: dataset.course_id,
      amount: 7000,
    });
    orderFunction();
  } else {
    setAmount(dataset?.partial_payment[0],);
    setSelectCourse({
      course_id: dataset.course_id,
      amount: dataset?.partial_payment[0],
    });
    orderFunction();
  }
}}
>Buy Now</button>  

      </Box>
    </Modal>
  );
};

export default BuyModal;