import Link from "next/link";

import CategoryEight from "../Category/CategoryEight";
import Card from "../Cards/Card";
import CounterFive from "../Counters/Counter-Five";
import styles from "@/styles/Login.module.css";
import TestimonialFive from "../Testimonials/Testimonial-Five";
import BlogGridMinimal from "../Blogs/BlogGridMinimal";
import NewsletterFour from "../Newsletters/Newsletter-Four";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanyDetails,
  fetchWebsiteDetails,
} from "@/redux/action/CompanyAction";
import { useRouter } from "next/router";
// import saveData from "@/utils/localStorage";

const Marketplace = () => {
  const router = useRouter();
  // const [bannerImg, setBannerImg] = useState("../images/bg/bg-image-19.jpg");
  const { orgid } = router.query;
  const dispatch = useDispatch();
  const [webDetails, setWebDetails] = useState([]);
  // const id = orgid;
  const id = orgid || "SMR-JP-758620";
  // localStorage.setItem("orgid", orgid);
  const [courses, setCourses] = useState([]);
  const company = useSelector((state) => {
    state.CompanyReducer.details;
  });
  const website = useSelector((state) => state.WebsiteReducer.web_details);
  const status = useSelector((state) => state.CompanyReducer.status);
  const web_status = useSelector((state) => state.WebsiteReducer.web_status);

  console.log("wb223", company);
  useEffect(() => {
    if (status === "idle") {
      id !== "" && id !== undefined && dispatch(fetchCompanyDetails(id));
    }
  }, [status, dispatch, id]);
  useEffect(() => {
    if (web_status !== "succeeded") {
      id !== "" && id !== undefined && dispatch(fetchWebsiteDetails(id));
    }
  }, [web_status, dispatch, id]);
  // const fetchBasicDetails = async () => {
  //   await axios
  //     .get(
  //       `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/website-details`,
  //       {
  //         params: {
  //           org_id: "SMR-JP-1254",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       setCourses(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const [bannerImg, setBannerImg] = useState("../images/bg/bg-image-19.jpg");
  const [bannerImg, setBannerImg] = useState("../images/banner/banner_new.png");

  const fetchCourses = async () => {
    await axios
      .get(
        `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/courses/course_register`,
        {
          params: {
            orgid: id,
            candidate_id: sessionStorage.getItem("candid"),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCourses(res.data);
      })
      .catch((err) => {
        console.log(err);
        setCourses([
          {
            course_id: "SMR_CO_Ca_Sa_915995",
            orgid: "SMR-JP-227785",
            name: "Advanced JavaScript Course",
            duration: 14,
            date_posting: "2024-01-01",
            valid_till: "2024-12-31T09:53:00Z",
            highest_qualification: "Bachelor's Degree",
            mode_of_course: "Online",
            state: "California",
            district: "San Francisco",
            address: "123 JavaScript St, San Francisco, CA",
            brochure: "",
            description:
              "An advanced course on JavaScript covering ES6+ features.",
            age_lower_range: 20,
            age_upper_range: 40,
            createdby: "SMR-SA-123456",
            createddt: "2024-05-31T13:09:42Z",
            modifieddt: "2024-05-31T13:09:42Z",
            modifiedby: "SMR-SA-123456",
            taluka: "SF Taluka",
            start_date: "2024-02-01T18:30:00Z",
            fees: 1500,
            spoc_name: "Jane Doe",
            spoc_email: "janedoe@example.com",
            spoc_mobile: "1234567890",
            active: 1,
            language: "English",
            discounted_fee: 0,
            skill_level_gain: "Intermediate to Advanced",
            no_of_sessions: "12",
            certificate_avail: 1,
            certificate_prototype_link: "https://example.com/certificate.pdf",
            course_key_points:
              "Advanced JavaScript, ES6+, Asynchronous programming",
            course_requirements:
              "Basic understanding of JavaScript and web development",
            instructor_name: "John Smith",
            course_title: "Advanced JavaScript",
            course_category: "Web Development",
            discount_percent: "0",
            course_img: "https://example.com/course_image.jpg",
            rating_number: "4.7",
            fees_applicable: 1,
            discount_applicable: 0,
            course_overview:
              "This course covers advanced topics in JavaScript, including modern ES6+ features and best practices.",
            org_name: "SecureDebt",
            assign_date: "",
            counsellor_name: "",
          },
        ]);
      });
  };
  useEffect(() => {
    // fetchBasicDetails();
    id !== "" && id !== undefined && fetchCourses();
  }, [id]);
  // wb_main_banner
  return (
    <>
      {/* <div className="rbt-banner-5 height-650 bg_image bg_image--19"> */}
      <div
        className="rbt-banner-5 height-650 bg_image"
        style={{
          backgroundImage: `url(${
            // website?.wb_main_banner ||
            bannerImg
          })`,
        }}
      >
        <div
          className="container"
          style={{
            // backgroundColor: "rgba(0, 0, 0, 0.75)",
            padding: "20px",
            marginLeft: "0px !important",
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className={styles.fifty}>
                {/* <div
                  className="inner text-start"
                >
                  <h2 className="title" style={{ maxWidth: "100% !important" }}>
                    Our Premier Workshops
                  </h2>

                  <p
                    className="description"
                  >
                    Our workshops are designed to help you acquire the most
                    essential skills from renowned industry professionals.
                    {website?.wb_desc ? website?.wb_desc : ""}
                  </p>

                  <div
                    className="slider-btn rbt-button-group justify-content-start"
                  >
                    <Link
                      className="rbt-btn btn-border icon-hover color-white radius-round"
                      href="/course-filter-one-toggle"
                    >
                      <span className="btn-text">Explore Courses</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div> */}
                <div
                  className="w-100 text-white border-radius-5"
                  style={{
                    padding: "30px",
                    backgroundColor: "rgb(0, 0, 0, 0.73)",
                    backdropFilter: "blur(8px)",
                    borderRadius: "10px",
                  }}
                >
                  <h2
                    className="title text-white"
                    style={{ maxWidth: "100% !important" }}
                  >
                    Our Premier Workshops
                  </h2>

                  <p className="description">
                    Our workshops are designed to help you acquire the most
                    essential skills from renowned industry professionals.
                    {/* {website?.wb_desc ? website?.wb_desc : ""} */}
                  </p>

                  <div className="slider-btn rbt-button-group justify-content-start">
                    <Link
                      className="rbt-btn btn-border icon-hover color-white radius-round"
                      href="/course-filter-one-toggle"
                    >
                      <span className="btn-text">Explore Courses</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-featured-course bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 id="courses" className="title">
                  Our Highest Rated Workshops
                </h2>
                <p className="description mt--20">
                  Our workshops are an excellent way for you to learn the most
                  in-demand skills from industry experts.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                {/* <Link
                  className="rbt-btn btn-border icon-hover radius-round"
                  href="/course-filter-one-toggle"
                  // href="#"
                >
                  <span className="btn-text">Browse Sikho Kamana Courses</span>
                  <span className="btn-icon">
                    <i className="feather-arrow-right"></i>
                  </span>
                </Link> */}
              </div>
            </div>
          </div>

          <div className="row g-5">
            <Card
              col="col-lg-4 col-md-6 col-sm-12 col-12"
              mt=""
              start={0}
              end={6}
              isDesc={true}
              isUser={true}
              courses={courses}
            />
          </div>
        </div>
      </div>

      <div
        className="rbt-counterup-area bg_image bg_image_fixed bg_image--20 ptb--170 bg-black-overlay"
        data-black-overlay="2"
      >
        <CounterFive />
      </div>

      <div className="rbt-testimonial-area bg-color-extra2 rbt-section-gap">
        <div className="container">
          <div className="testimonial-item-3-activation swiper rbt-arrow-between gutter-swiper-30">
            <TestimonialFive isDesc={true} />
          </div>
        </div>
      </div>

      {/* <div className="rbt-rbt-blog-area rbt-section-gapTop bg-gradient-8 rbt-round-bottom-shape">
        <div className="wrapper pb--50 rbt-index-upper">
          <div className="container">
            <div className="row g-5 align-items-end mb--60">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="section-title text-start">
                  <h2 className="title color-white">Latest News</h2>
                  <p className="description color-white-off mt--20">
                    Learning communicate to global world and build Link bright
                    future and career development, increase your skill with our
                    Sikho Kamana.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="load-more-btn text-start text-lg-end">
                  <Link
                    className="rbt-btn btn-border icon-hover radius-round color-white-off"
                    // href="/blog-list"
                    href="#"

                  >
                    <span className="btn-text">See All Articles</span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <BlogGridMinimal start={0} end={3} isPagination={false} />
          </div>
        </div>
      </div> */}

      {/* <div className="rbt-newsletter-area bg-color-white rbt-section-gapBottom pt--60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <NewsletterFour />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Marketplace;
