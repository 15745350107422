export const fetchCompanyDetails = (companyId) => async (dispatch) => {
  dispatch({ type: "FETCH_COMPANY_DETAILS_REQUEST" });
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/jobprovider/dashboard/fetch/org/details?org_id=${companyId}`
    );
    const data = await response.json();

    console.log(data, "wb110");
    dispatch({
      type: "FETCH_COMPANY_DETAILS_SUCCESS",
      payload: data.JobProvider_List[0],
    });
  } catch (error) {
    dispatch({ type: "FETCH_COMPANY_DETAILS_FAILURE", payload: error.message });
  }
};

export const fetchWebsiteDetails = (companyId) => async (dispatch) => {
  dispatch({ type: "FETCH_WEBSITE_DETAILS_REQUEST" });
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/website-details?org_id=${companyId}`
    );
    const data = await response.json();
    console.log(data, "wb119");
    dispatch({ type: "FETCH_WEBSITE_DETAILS_SUCCESS", payload: data.data[0] });
  } catch (error) {
    dispatch({ type: "FETCH_WEBSITE_DETAILS_FAILURE", payload: error.message });
  }
};
