import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import axios from "axios";
import Swal from "sweetalert2";
import { useRouter } from "next/router";
import Button from "react-bootstrap/Button";
import { useAppContext } from "@/context/Context";
import { useSelector } from "react-redux";

import Pagination from "@/components/Common/Pagination";
// import CourseDetails from "../../../data/course-details/courseData.json";
import { Router } from "next/router";
import CourseDetails from "../../data/course-details/courseData.json";
import { loadData } from "@/utils/localStorage";
import MyModal from "@/components/Modal/MyModal";
import BuyModal from "../Modal/BuyModal";

const Card = ({ start, end, col, mt, isDesc, isUser, courses }) => {
  console.log("cr11", courses);
  // console.log("cr11", CourseDetails.courseDetails);
  const defaultImg = CourseDetails?.courseDetails[0]["courseImg"];
  const userImg = CourseDetails?.courseDetails[0]["userImg"];
  const { cartToggle, setCart } = useAppContext();
  const [dataset, setDataset] = useState({});
  const [myData, setMyData] = useState(null);
  const user = useSelector((state) => state.LoginReducer.details);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    setMyData(loadData("user"));
  }, []);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [regId, setRegId] = useState("");
  const [newState, setNewState] = useState("");
  const [newDistrict, setNewDistrict] = useState("");
  const [proff, setProff] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [value, setValue] = useState(0);
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [step1Details, setStep1Details] = useState({
    // aadhaar_no: "545546665",
    // // aadhaar_no: "",
    // pan: "",
    // address: "dhsj kjskhhsg kjhdhsb",
    // // address: "",
    // mobile: "8154584845",
    // first_name: "ggdh hsgdj",
    // doner_code: "SBA00015223",
  });
  const [amount, setAmount] = useState(0);
  const [pin, setPin] = useState(false);
  const [check, setCheck] = useState(false);
  // const [orderDetails, setOrderDetails] = useState({});
  const [paymentID, setPaymentID] = useState("");
  const [step2Details, setStep2Details] = useState({});
  const [donorID, setDonorID] = useState("");
  const [orderId, setOrderId] = useState("");
  const [selectCourse, setSelectCourse] = useState({});
  const [failureModal, setFailureModal] = useState(false);
  const [open1, setOpen1] = useState(false);
  const candid = useSelector((state) => state);
  const router = useRouter();

  const orderFunction = (fees) => {
    axios
      .post(
        // "https://mysba.donation.middleware.uat.globalsapio.com/transaction/orderId/",
        `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/SMR-JP-758620/payment/create-order`,
        // {
        //   amount: amount * 100,
        //   mobile: regId,
        // }
        {
          candid: sessionStorage.getItem("candid"),
          courses: [selectCourse],
        }
      )
      .then((res) => {
        console.log("ordId ", res.data.order_detail.id);
        setOrderId(res.data.order_detail.id);
        sessionStorage.setItem("session_order_id", res.data.order_detail.id);
        console.log(typeof res.data.order_detail.amount);
        openPayModal(
          {
            ...options,
            order_id: res.data.order_detail.id,
            amount: parseInt(fees),
          }
          // step1Details
        );

        console.log(options);
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 504) {
          window.location.reload();
        } else {
          // Swal.fire({
          //   imageUrl: "https://cdn-icons-png.flaticon.com/512/5219/5219070.png",
          //   imageHeight: 70,
          //   confirmButtonColor: "#F29313",
          //   // timer: 2000,
          //   // title: "Oops...",
          //   // text: err.response.data["User Message"]
          //   //   ? err.response.data["User Message"]
          //   //   : "We could not save your details due to connection issues. Please try again.",
          //   text: "We could not save your details due to connection issues. Please try again.",
          //   showConfirmButton: true,
          // });
        }
      });
  };

  const handleClose1 = () => {
    setAmount(``);
    setSelectCourse({});

    setOpen1(false);
  };

  useEffect(() => {
    if (amount > 0) {
      orderFunction();
    }
  }, [amount]);

  console.log(amount);

  const step2Func = async (
    trans_id,
    razorpay_order_id,
    razorpay_status_code
  ) => {
    console.log(step1Details, razorpay_status_code);
    console.log("did", donorID);
    const successPayloadData = {
      doner: sessionStorage.getItem("orderID"),
      payment_amount: amount,
      order_id: razorpay_order_id,
      transaction_id: trans_id,
      payment_status: "success",
    };
    const failedPayloaadData = {
      doner: sessionStorage.getItem("orderID"),
      payment_amount: amount,
      order_id: razorpay_order_id,
      transaction_id: trans_id,
      // payment_status:
    };

    await axios

      .post(
        // "https://mysba.donation.middleware.uat.globalsapio.com/transaction/paymentDetails/",
        `${
          process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL
        }/skilling/${"SMR-JP-758620"}/payment/capture-payment`,
        razorpay_status_code === undefined
          ? failedPayloaadData
          : successPayloadData
      )
      .then((res) => {
        router.push("/learnings");
        console.log("res ", res.data);
        setStep2Details(res.data["data"]);
      })
      .catch((err) => {
        router.push("/learnings");
        console.log("error ", err);
        if (err.response.status === 504) {
          window.location.reload();
        } else {
          Swal.fire({
            imageUrl: "https://cdn-icons-png.flaticon.com/512/5219/5219070.png",
            imageHeight: 70,
            confirmButtonColor: "#F29313",
            // timer: 2000,
            // title: "Oops...",
            // text: err.response.data["User Message"]
            //   ? err.response.data["User Message"]
            //   : "We could not save your details due to connection issues. Please try again.",
            text: "We could not save your details due to connection issues. Please try again.",
            showConfirmButton: true,
          });
        }
      });
  };

  const options = {
    key: "rzp_live_ZTZjf49jE56fr6", //latest key artha
    // key: `${process.env.REACT_RAZ_PAY_KEY_ID}`,
    // key: "rzp_live_6zUezOdpHD5SwU",
    // amount: 100 * amount, //  = INR 1 // state amount *100
    amount: amount * 100,
    name: "Shikho Kamana", //
    description: "Shikho Kamana",
    // image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png", //favicon
    image: "",
    order_id: "",
    // order_id: sessionStorage.getItem("session_order_id"),
    // payment_capture: 1,
    handler: function (response) {
      var rzp1 = new window.Razorpay(options);
      // rzp1.on("payment.failed", function (response) {
      //   alert(response.error.code);
      //   alert(response.error.description);
      //   alert(response.error.source);
      //   alert(response.error.step);
      //   alert(response.error.reason);
      //   alert(response.error.metadata.order_id);
      //   alert(response.error.metadata.payment_id);
      // alert(response.razorpay_signature);
      // });
      console.log("response after payment ", response);
      // alert(response.razorpay_payment_id);
      {
        /* razorpay response */
      }
      // razorpay_order_id: "order_Ltnl6HF5FqZNCM";
      // razorpay_payment_id: "pay_LtnnLp1eoZlalq";
      // razorpay_signature: "f8b671836f04e9e501dc27d01466ee0a0565e630573ee529cdc45a0bc8d1d236";
      // status_code: 200;
      {
        /* razorpay response */
      }
      setPaymentID(response.razorpay_payment_id);
      console.log(response.razorpay_order_id);
      step2Func(
        response.razorpay_payment_id,
        sessionStorage.getItem("session_order_id"),
        response.status_code
      );
      Swal.fire({
        position: "top",
        // icon: "success",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfZRiXeOzVJ5oKzD80rablYg01FNAq3cno-D3VshcD_Q&s",
        imageHeight: 70,
        title: "Payment Successful",
        text: `Thank you for your payment. The session link will be shared with you two days prior to the course date.`,
        showConfirmButton: false,
        timer: 3000,
      });
      // .then((res) => {
      //   handleNext();
      //   handleTopScroll();
      // });
    },
    // prefill: {
    //   name: step1Details.first_name,
    //   contact: step1Details.mobile,
    //   email: "",
    // },
    // notes: {
    //   address: step1Details.address,
    // },
    theme: {
      color: "#05be64",
      hide_topbar: false,
    },
  };

  // useEffect(function onFirstMount() {
  //   function onScroll() {
  //     console.log("scroll!");
  //   }
  console.log(options);
  console.log(typeof window);
  var rzp1 = typeof window !== "undefined" ? new window.Razorpay(options) : "";

  const openPayModal = (options, order) => {
    console.log("options ", options);
    console.log("openmodal order", options.order_id);

    setStep1Details(order);

    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      console.log("error failed response ", response);
      step2Func(
        response.error.metadata.payment_id,
        sessionStorage.getItem("session_order_id")
      );
      // setFailureModal(true);
    });
    // rzp1.close();
  };

  const addToInterest = async (course_id) => {
    if (myData !== null) {
      await axios
        .post(
          `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/interested-course`,
          {
            candid: myData?.registration_id,
            course_id: course_id,
          }
        )
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            //position: "top",
            icon: "success",
            title: `${res.data.message}`,
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
          }).then(() => {
            setCart(!cartToggle);
            //  fetchInterested();

            sessionStorage.setItem("cart", true);
          });
        })
        .catch((err, res) => {
          Swal.fire({
            //position: "top",
            icon: "info",
            title: `${err.response.data.message}`,
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
          }).then(() => {
            setCart(!cartToggle);
            //  fetchInte rested();
            sessionStorage.setItem("cart", true);
          });
        });
    } else {
      Swal.fire({
        //position: "top",
        icon: "info",
        title: `Please login`,
        // text: res.response.data["User Message"],
        showConfirmButton: true,
        timer: 3000,
      });
    }
  };

  return (
    <>
      {/* {CourseDetails &&CourseDetails.courseDetails */}
      {courses.length > 0 &&
        courses
          .slice(start, end)
          .sort((a, b) => b.fees - a.fees)
          .map((data, index) => (
            <div
              className={`${col} ${mt}`}
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
              key={index}
            >
              <div className="rbt-card variation-01 rbt-hover">
                <div className="rbt-card-img">
                  {/* <Link href={`/course-details/${data.id}`}> */}
                  <Image
                    // src={data.courseImg}
                    src={
                      data.course_img
                        ? data.course_img
                        : CourseDetails?.courseDetails[0][`courseImg${index}`]
                    }
                    width={355}
                    height={244}
                    alt="Card image"
                  />
                  {data.fees === 1 && (
                    <Button
                      variant="warning"
                      style={{
                        fontSize: `20px`,
                        width: `200px`,
                        marginTop: `5px`,
                      }}
                    >
                      Upcoming
                    </Button>
                  )}
                  {data.offPrice > 0 ? (
                    <div className="rbt-badge-3 bg-white">
                      <span>-{data.offPrice}%</span>
                      <span>Off</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* </Link> */}
                </div>
                <div className="rbt-card-body">
                  <div className="rbt-card-top">
                    <div className="rbt-review">
                      {/* <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <span className="rating-count">
                      ({data.review} Reviews)
                    </span> */}
                    </div>
                    <div
                      className="rbt-bookmark-btn"
                      onClick={() => {
                        // addToCartFun(checkMatchCourses.course_id, 1, checkMatchCourses);
                        if (data.is_purchased) {
                          Swal.fire({
                            //position: "top",
                            icon: "info",
                            title: `Course has already purchased`,
                            // text: res.response.data["User Message"],
                            showConfirmButton: true,
                            timer: 3000,
                          }).then(() => {
                            router.push("/learnings");
                          });
                        } else {
                          addToInterest(data.course_id);
                        }
                      }}
                    >
                      <Link
                        className="rbt-round-btn"
                        title="Add to Interest"
                        href=""
                      >
                        <i className="feather-bookmark"></i>
                      </Link>
                    </div>
                  </div>

                  <h4 className="rbt-card-title">
                    {/* <Link href={`/course-details/${data.id}`}> */}
                    {data.name}
                    {/* </Link> */}
                  </h4>
                  <div
                    className="rbt-author-info"
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  ></div>

                  {/* <ul className="rbt-meta">
                  <li>
                    <i className="feather-book"></i>
                    {data.lesson} Lessons
                  </li>
                  <li>
                    <i className="feather-users"></i>
                    {data.student} Students
                  </li>
                </ul> */}
                  {isDesc ? <p className="rbt-card-text">{data.desc}</p> : ""}
                  {isUser ? (
                    <div className="rbt-author-meta mb--10">
                      <div className="rbt-avater">
                        {/* <Link href={`/profile/${data.id}`}> */}
                        <Image
                          src={data.userImg || userImg}
                          width={33}
                          height={33}
                          alt="Sophia Jaymes"
                        />
                        {/* </Link> */}
                      </div>
                      <div className="rbt-author-info">
                        {/* By{" "}
                      <Link href={`/profile/${data.id}`}>{data.userName}</Link>{" "}
                      In <Link href="#">{data.userCategory}</Link> */}
                        {data.instructor_name}
                        {/* In{" "}
                      {data.course_category} */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.fees !== 1 && (
                    <div className="rbt-card-bottom">
                      <div className="rbt-price">
                        <span className="current-price">₹{data.fees}</span>
                        {data.discounted_fee > 0 && (
                          <span className="off-price">
                            ₹{data.discounted_fee}
                          </span>
                        )}
                      </div>
                      <Link
                        className="rbt-btn-link"
                        href={`/course-details/${data.course_id}`}
                      >
                        Learn More<i className="feather-arrow-right"></i>
                      </Link>
                    </div>
                  )}
                  {data.fees !== 1 && (
                    <>
                      {myData !== null ? (
                        <div>
                          {!data.is_purchased ? (
                            <Link
                              className="rbt-btn btn-border icon-hover w-100 d-block text-center"
                              href=""
                              // onClick={()=>{openPayModal({
                              //   ...options,
                              //   order_id: "",
                              //   amount: parseInt(data.fees),
                              // },); setAmount(data.fees)}}
                              onClick={() => {
                                console.log(`running`);

                                if (!data.is_partial_payment) {
                                  // If fees is not equal to 12500
                                  // setOpen1(true); // Commented out since it's not needed here
                                  setAmount(parseInt(data.fees));
                                  setSelectCourse({
                                    course_id: data.course_id,
                                    amount: parseInt(data.fees),
                                  });
                                  orderFunction();
                                } else {
                                  // If fees is equal to 12500
                                  setOpen1(true);
                                  setDataset(data);
                                  // orderFunction(); // Commented out since it's not needed here
                                }
                              }}
                            >
                              <span className="btn-text">Buy Now</span>
                              <span className="btn-icon">
                                <i className="feather-arrow-right"></i>
                              </span>
                            </Link>
                          ) : (
                            <Link
                              className="rbt-btn btn-border icon-hover w-100 d-block text-center"
                              href="/learnings"
                              // onClick={()=>{openPayModal({
                              //   ...options,
                              //   order_id: "",
                              //   amount: parseInt(data.fees),
                              // },); setAmount(data.fees)}}
                            >
                              {/* <span>test</span> */}
                              <span className="btn-text">Purchased</span>
                              <span className="btn-icon">
                                <i className="feather-arrow-right"></i>
                              </span>
                            </Link>
                          )}
                        </div>
                      ) : (
                        <div
                          className="checkout-btn mt--20"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="rbt-btn btn-border icon-hover w-100 d-block text-center"
                            onClick={() => {
                              console.log(myData, "is11");
                              handleOpen();
                            }}
                          >
                            <span className="btn-text">Buy Now</span>
                            <span className="btn-icon">
                              <i className="feather-arrow-right"></i>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}

      <MyModal open={open} handleClose={handleClose} handleOpen={handleOpen} />
      <BuyModal
        open={open1}
        dataset={dataset}
        setAmount={setAmount}
        setSelectCourse={setSelectCourse}
        orderFunction={orderFunction}
        handleClose={handleClose1}
      />
    </>
  );
};

export default Card;
